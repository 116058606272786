import { FC } from 'react'
import { styled } from '@liftfoils/styles'
import { MediaFieldType, PageLink } from '@liftfoils/models'
import { Media, Link, Button } from '@liftfoils/components'

const Card = styled('div', {
  position: 'relative',
  width: '100%',
  textAlign: 'center',
  $mb: 'L',
})

const MediaWrapper = styled('div', {
  borderRadius: '$r1',
  overflow: 'hidden',
  transform: 'translateZ(0)',
  filter: 'drop-shadow(0px 0px 35px rgba(0, 0, 0, 0.3))',
})

const Title = styled('div', {
  lift_font: 'heading01',
  mt: '$8',
  '+ *': {
    mt: '$8',
  },
})

export type UnequalCardProps = {
  title?: string
  link?: PageLink
  buttonLabel?: string
  media?: MediaFieldType
}

const UnequalCard: FC<UnequalCardProps> = ({
  title,
  link,
  buttonLabel,
  media,
}) => {
  return (
    <Link {...link} tabIndex={-1}>
      <Card>
        {media && (
          <MediaWrapper>
            <Media
              {...media}
              hardcropRatio="portrait2"
              sizes="(min-width: 1200px) 318px, (min-width: 740px) 30vw, 90vw"
              alt={media?.alt ?? ''}
            />
          </MediaWrapper>
        )}

        {title && <Title>{title}</Title>}

        {buttonLabel && (
          <Button appearance={'solidWhite'} size={'medium'}>
            {buttonLabel}
          </Button>
        )}
      </Card>
    </Link>
  )
}

export default UnequalCard
