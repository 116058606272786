import { FC } from 'react'

import { styled } from '@liftfoils/styles'
import { SectionUnequalCardsProps } from '@liftfoils/models'
import { Container, PortableText } from '@liftfoils/components'

import UnequalCard from './UnequalCard'

const Wrap = styled('div', {
  position: 'relative',
  $py: 'L',
  color: 'white',
  backgroundColor: '$navy800',
})

const Heading = styled('div', {
  position: 'relative',
  maxWidth: '765px',
  textAlign: 'center',
  mx: 'auto',
  display: 'grid',
  gridRowGap: '$9',
})

const Title = styled('h2', {
  lift_font: 'caps02',
})

const Caption = styled('div', {
  lift_font: 'heading01',
  color: '$white_06',
  '.highlighted': {
    color: 'white',
  },
})

const CardsWrapper = styled('div', {
  display: 'grid',
  gridColumnGap: '$11',
  maxWidth: '1082px',
  mx: 'auto',
  $mt: 'XXL',
  '> *:nth-child(2)': {
    '@md': {
      $mt: 'XL',
    },
  },
  '> *:nth-child(3)': {
    '@md': {
      $mt: 'S',
    },
  },
  '@md': {
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  },
})

export const SectionUnequalCards: FC<SectionUnequalCardsProps> = ({
  title,
  caption,
  cards,
}) => {
  return (
    <Wrap>
      <Container>
        <Heading>
          {title && <Title>{title}</Title>}

          {caption && (
            <Caption>
              <PortableText value={caption} />
            </Caption>
          )}
        </Heading>
        <CardsWrapper>
          {cards?.map(({ _key, title, buttonLabel, buttonLink, image }) => (
            <UnequalCard
              key={_key}
              title={title}
              link={buttonLink}
              buttonLabel={buttonLabel}
              media={image}
            />
          ))}
        </CardsWrapper>
      </Container>
    </Wrap>
  )
}
